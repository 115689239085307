import React from 'react'
import Helmet from 'react-helmet'

import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Img from 'gatsby-image'

import Layout from 'src/components/layout'
import Row from 'src/components/row'
import CTA from 'src/components/cta'

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>
        Residential Plubming & Drain - {data.site.siteMetadata.title}
      </title>
    </Helmet>
    <div className="content">
      <Slider
        dots
        autoplay
        speed={500}
        slidesToScroll={1}
        slidesToShow={3}
        arrows={false}
        variableWidth
        adaptiveHeight
        responsive={[{breakpoint: 480, settings: {slidesToShow: 1}}]}
      >
        {data.images.edges.map(({ node: { childImageSharp: { fixed } } }) => (
          <div key={fixed.src}>
            <Img fixed={fixed}  style={{margin: "10px"}}/>
          </div>
        ))}
      </Slider>

        <div>
          <h1>Residential Plumbing</h1>
          <p>
            Arc Plumbing is pleased to offer a number of residential plumbing
            services.  From unclogging a toilet to designing the master ensuite
            of your dreams we are the plumber for you!
          </p>

          <p>
            We are also pleased to offer a number of troubleshooting services.
            Does your drain keep clogging?  Is your kitchen sink backing up?
            Does the shower drain slowly?  Stinky laundry room drains?  New
            dishwasher need to be installed?  We can help!
          </p>

          <p>These include:</p>
          <ul>
            <li>New construction plumbing</li>
            <li>Remodel plumbing; planning + budgeting, design, supply and install</li>
            <li>Restoration plumbing</li>
            <li>Residential backwater valves</li>
            <li>Troubleshooting + investigative plumbing solutions</li>
            <li>Supply + install sump Pumps</li>
            <li>Supply + installation of new plumbing fixtures</li>
            <li>Supply + install hot water tanks</li>
            <li>Upgrades to water service line size from the City</li>
          </ul>
        </div>
    </div>
    <CTA />
    <div className="content">
      <div>


          <h1>Here is a detailed list of the services we offer</h1>
          <Row columns={2}>
            <div>
              <h2>Drain camera inspection</h2>
              <p>
                View inside drain pipes to determine
                interior conditions and investigate ongoing and repeated drain
                clogs.  The camera can help provide insight if something is in need
                of attention inside a drain line.  We often find tree roots in the
                drain are a big part of the problem!
              </p>
            </div>
            <div>
              <h2>Drain Locate</h2>
              <p>
                Identify drain line location and depth below ground
              </p>
            </div>
            <div>
              <h2>Clearing and repair of blocked drains</h2>
              <p>
                Unclog drains.  We find everything in drains such as grease, roots,
                children's toys, etc. We also excavate and perform repairs to
                piping, fixing cracked and broken pipes, sagging drain lines and
                poorly sloped pipes.
              </p>
            </div>
            <div>
              <h2>Kitchen plumbing</h2>
              <p>
                Install kitchen faucet, kitchen sink installation,
                dishwasher installation, garburator, slow draining kitchen sinks, low
                water pressure, instant hot, filtered water system
              </p>
            </div>
            <div>
              <h2>Bathroom plumbing</h2>
              <p>
                Install sink and faucet, toilet replacement, bathtub replacement, shower valve.
              </p>
            </div>
            <div>
              <h2>Laundry plumbing</h2>
              <p>
                Install laundry sink and tub, faucet install, washing machine plumbing.
              </p>
            </div>
            <div>
              <h2>Water service upgrades</h2>
              <p>
                Replacement of private water lines. Replace main water shutoff valve at meter.
              </p>
            </div>
            <div>
              <h2>Fix low water pressure</h2>
              <p>
                Replace clogging and plugged up low water pressure pipes. Booster pump installations.
              </p>
            </div>
            <div>
              <h2>Backwater valves</h2>
              <p>
                Sewer backwater valves installed. Rebates may apply.
              </p>
            </div>
            <div>
              <h2>Diagnose and repair leaks</h2>
              <p>
                Troubleshooting leaks and repairing water
                and drain lines.
              </p>
            </div>
            <div>
              <h2>Hot water tanks</h2>
              <p>
                Hot water tank replacement. Tankless water heater
                replacements.
              </p>
            </div>
            <div>
              <h2>Frozen pipes</h2>
              <p>
                Blocked frozen pipe thawing, burst frozen pipe repairs, frozen
                garden hose valve repairs, heat tracing water lines.
              </p>
            </div>
            <div>
              <h2>Irrigation system service</h2>
              <p>
                Lawn and garden sprinkler installations,
                water supply connections, repairs, start-up, winterization.
              </p>
            </div>
          </Row>
        </div>
    </div>
  </Layout>
)

export const query = graphql`
  query ResidentialPlumbingQuery {
    site {
      siteMetadata {
        title
      }
    }

    images: allFile(filter: { relativeDirectory: { regex: "/residential-slideshow/" }},
                    sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fixed(height: 300, fit: INSIDE) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
